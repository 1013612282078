export const helper = {
  saveToBrowser,
};

function saveToBrowser(filename, data) {
  let fileURL = window.URL.createObjectURL(
    new Blob([data], { type: "application/octet-stream" })
  );
  let fileLink = document.createElement("a");

  fileLink.href = fileURL;
  fileLink.target = "_blank";
  fileLink.download = filename;
  document.body.appendChild(fileLink);

  fileLink.click();
}
